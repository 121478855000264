import { FunctionComponent, useEffect, useMemo } from 'react';

import { OrganizationSector } from '../organizations/organizationSector';
import useSignicatIframe, { SignicatIframeStatus } from './useSignicatIframe';

const SignicatIframe: FunctionComponent<SignicatIframeProps> = ({
  signicatIframeLink,
  jobSenderSector,
  successCallback,
  cancelCallback,
  failureCallback,
}) => {
  const currentIframeStatus = useSignicatIframe();
  useEffect(() => {
    if (currentIframeStatus === SignicatIframeStatus.SUCCESS) {
      successCallback();
    } else if (
      cancelCallback &&
      currentIframeStatus === SignicatIframeStatus.CANCEL
    ) {
      cancelCallback();
    } else if (currentIframeStatus !== SignicatIframeStatus.START) {
      failureCallback();
    }
  }, [currentIframeStatus, successCallback, cancelCallback, failureCallback]);

  const iframeCss = useMemo(
    () =>
      jobSenderSector === OrganizationSector.PRIVATE
        ? 'full-width full-height'
        : 'public-advanced-signature-iframe',
    [jobSenderSector]
  );

  return currentIframeStatus === SignicatIframeStatus.START ? (
    <iframe
      title="Signicat"
      ref={(domElement) => {
        if (domElement) domElement.focus();
      }}
      aria-busy="false"
      src={signicatIframeLink}
      className={iframeCss}
      frameBorder="0"
    />
  ) : null;
};

interface SignicatIframeProps {
  signicatIframeLink: string;
  jobSenderSector?: OrganizationSector;
  successCallback: () => void;
  cancelCallback?: () => void;
  failureCallback: () => void;
}

export default SignicatIframe;
