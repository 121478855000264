import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { App } from '../../apps';
import { useTracking } from '../analytics/Analytics';
import { useI18n, withParams } from '../language/i18n';
import {
  PrimaryButton,
  SecondaryOutlinedButton,
  sizes,
  widths,
} from '@hedwig/buttons';
import { OpenPortalJobSender } from 'content/js/openPortal/jobs/openPortalSignatureJob';

const UnsignedJobButtons: FunctionComponent<UnsignedJobButtonsProps> = ({
  id,
  sender,
  subject,
  setRejectWarningVisible,
  setFocus,
  startSigningUrl,
  app,
}) => {
  const navigate = useNavigate();
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <div className="unsigned-job__sign-buttons hw-block--mt-medium-2">
      <PrimaryButton
        id={`start-signing-button-${id}`}
        dataTestid={`start-signing-button-unsigned-list-${id}`}
        extraClassName="unsigned-job__sign-buttons--sign hw-block--mt-small-4"
        size={sizes.large}
        width={widths.responsive}
        onClick={() => {
          registerAnalyticsEvent({
            category: app,
            action: 'Starter signering',
          });
          navigate(startSigningUrl);
        }}
      >
        {translation.portal.list.start_signing}{' '}
        {subject ? (
          <span className="screen-reader-only">{subject}</span>
        ) : (
          <span className="screen-reader-only">
            {withParams(translation.portal.list.standard_non_sensitive_title, {
              sender: sender.name,
            })}
          </span>
        )}
      </PrimaryButton>
      <SecondaryOutlinedButton
        extraClassName="unsigned-job__sign-buttons--reject hw-block--mt-small-4"
        dataTestid="unsigned-job-reject-button"
        size={sizes.large}
        width={widths.responsive}
        onClick={() => {
          setRejectWarningVisible(true);
          setFocus('reject-job-warning');
        }}
      >
        {translation.portal.list.reject_signing}
      </SecondaryOutlinedButton>
    </div>
  );
};

interface UnsignedJobButtonsProps {
  id: number | string;
  subject?: string;
  sender: Pick<OpenPortalJobSender, 'name'>;
  setFocus: (focus: string) => void;
  setRejectWarningVisible: (warn: boolean) => void;
  startSigningUrl: string;
  app: App;
}

export default UnsignedJobButtons;
