import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '../basicFunctions';

import './information-container.less';

const InformationContainer: FunctionComponent<
  InformationContainerProps & PropsWithChildren
> = ({ children, extraClassName, dataTestid }) => (
  <div
    data-testid={dataTestid}
    className={`information-container hw-block hw-background-color-primary-lighter hw-block--px-fluid${addClassesFromProps(
      extraClassName
    )}`}
  >
    {children}
  </div>
);

interface InformationContainerProps {
  extraClassName?: string;
  dataTestid?: string;
}

export default InformationContainer;
