import { FunctionComponent, useEffect } from 'react';

import useSignicatIframe, {
  SignicatIframeStatus,
} from '../../signicat/useSignicatIframe';
import { useToggleScrollContext } from '../../scroll/AppWithScrollToggle';

const AuthenticatedSignatureDocument: FunctionComponent<
  AuthenticatedSignatureDocumentProps
> = ({ cancelSignature, signicatIframeLink }) => {
  const currentIframeStatus = useSignicatIframe();
  const { setDisableScroll } = useToggleScrollContext();

  useEffect(() => {
    {
      setDisableScroll(true);
    }
    return () => setDisableScroll(false);
  });

  useEffect(() => {
    if (currentIframeStatus === SignicatIframeStatus.CANCEL) {
      cancelSignature();
    }
  }, [currentIframeStatus, cancelSignature]);

  return (
    <iframe
      title="Signicat"
      data-cy="signicat-iframe"
      ref={(domElement) => {
        if (domElement) domElement.focus();
      }}
      src={signicatIframeLink}
      className="full-height full-width"
      frameBorder="0"
    />
  );
};

interface AuthenticatedSignatureDocumentProps {
  signicatIframeLink: string;
  cancelSignature: () => void;
}

export default AuthenticatedSignatureDocument;
