import { FunctionComponent } from 'react';

import { addClassesFromProps } from '../../basicFunctions';
import Curtain from '@hedwig/curtain/Curtain';
import { useI18n } from '../../language/i18n';
import { IconSize } from '@hedwig/icons';

import './job-document-list.less';

const DocumentLink: FunctionComponent<DocumentLinkProps> = ({
  link,
  name,
  text,
  extraClasses,
}) => {
  return (
    <a
      href={link}
      className={`unsigned-job__document-link jobs-table__text-left${addClassesFromProps(
        extraClasses
      )}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text ? (
        <span
          className="hw-link hw-link--inline"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : (
        name
      )}
    </a>
  );
};

interface DocumentLinkProps {
  link: string;
  name?: string;
  text?: string;
  extraClasses?: string;
}

export const JobDocumentsList: FunctionComponent<JobDocumentsListProps> = ({
  documents,
}) => {
  const [translation] = useI18n();
  return documents.length === 1 ? (
    <div>
      <DocumentLink
        link={documents[0].link}
        text={translation.common.signaturejob.show_document_singular}
      />
    </div>
  ) : (
    <div className="hw-block--pt-small-2">
      <Curtain
        text={translation.common.signaturejob.show_documents_plural}
        wrapperClassName="multiple-document-list"
        triggerClassName="multiple-document-list__collapse_button"
        contentClassName="multiple-document-list__curtain-content"
        iconSize={IconSize.SMALL}
      >
        <div className="hw-wysiwyg">
          <ul className="hw-list hw-list--no-bullets multiple-document-list multiple-document-list_items-container">
            {documents.map((document, i) => {
              return (
                <li key={i} className="multiple-document-list_list-item">
                  <DocumentLink
                    link={document.link}
                    name={document.title || document.name}
                    extraClasses="multiple-document-list_link"
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </Curtain>
    </div>
  );
};

export interface JobDocument {
  link: string;
  title?: string;
  name?: string;
}

interface JobDocumentsListProps {
  documents: Array<JobDocument>;
}
