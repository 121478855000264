import { FunctionComponent, useMemo } from 'react';

import isHiDPI from '../../browsersupport/hidpi';
import { addClassesFromProps } from '../../basicFunctions';

import './organization-logo.less';

interface OrganizationLogoProps {
  logoUrl?: string;
  extraClassName?: string;
  alt?: string;
}

const OrganizationLogo: FunctionComponent<OrganizationLogoProps> = ({
  logoUrl,
  extraClassName,
  alt = '',
}) => {
  const optimizedLogoUrl = useMemo(
    () =>
      logoUrl
        ? `${logoUrl}?maxWidth=${isHiDPI() ? 300 : 150}&maxHeight=${
            isHiDPI() ? 100 : 50
          }`
        : undefined,
    [logoUrl]
  );

  return optimizedLogoUrl ? (
    <img
      src={optimizedLogoUrl}
      className={`organization-logo${addClassesFromProps(extraClassName)}`}
      alt={alt}
    />
  ) : null;
};

export default OrganizationLogo;
