import { createDataFetcher } from '../api/dataFetcher';
import { getLocation } from '../api/signeringApi';

export default createDataFetcher({
  name: 'CreateSignicatIframeLink',
  url: (link: string) => link,
  apiCaller: (url: string, referer?: string) =>
    getLocation(
      url,
      referer !== undefined ? { ['X-full-referer-url']: referer } : undefined
    ),
});
