import HelpContent from '@common/help/HelpContent';
import { FunctionComponent } from 'react';
import { useI18n, withParams } from '@common/language/i18n';
import { linkToInternalSite } from '../navigation/externalNavigation';

const About: FunctionComponent = () => {
  const [translation] = useI18n();
  return (
    <HelpContent
      title={translation.common.help.topics.about.sub_topic}
      helpSubTopics={[
        {
          name: 'hvem-kan',
          headingTextKey:
            translation.common.help.topics.about.who_can_use_heading,
          bodyTextKeys: [
            translation.common.help.topics.about.who_can_use_body,
            translation.common.help.topics.about.who_can_use_body_2,
          ],
        },
        {
          name: 'kom-igang',
          headingTextKey:
            translation.common.help.topics.about.get_started_heading,
          bodyTextKeys: [
            withParams(translation.common.help.topics.about.get_started_body, {
              registreringUrl: linkToInternalSite('/registrering').href,
              virksomhetUrl: linkToInternalSite('/virksomhet').href,
            }),
            withParams(
              translation.common.help.topics.about.get_started_persons_body,
              { url: linkToInternalSite('/privat/lastopp').href }
            ),
          ],
        },
        {
          name: 'pris',
          headingTextKey: translation.common.help.topics.about.pricing_heading,
          bodyTextKeys: [
            withParams(translation.common.help.topics.about.pricing_body_p1, {
              url: linkToInternalSite('/priser').href,
            }),
          ],
        },
      ]}
    />
  );
};

export default About;
