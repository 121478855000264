import { ForwardSignedDocumentStatus } from '../../forwarding/forwardSignedDocumentStatus';
import { RecentlySignedJob } from './recentlySignedJobs';

export const VIEWS_COMPLETED_JOBS_ACTION =
  'Så listen over ferdigsignerte oppdrag';

export const completedJobsUpsaleState = {
  SHOWS_DIGIPOST_UPSALE: 'Ble vist Digipost oppsalg',
  SHOWS_PUBLIC_UPSALE: 'Ble vist offentlig oppsalg',
  SHOWS_BOTH_DIGIPOST_AND_PUBLIC_UPSALES:
    'Ble vist både Digipost og offentlig oppsalg',
  SHOWS_NO_UPSALE: 'Ble ikke vist noen oppsalg',
};

export const getUpsaleState = (jobs: RecentlySignedJob[]) => {
  const showsDigipostUpsale = jobs.some(
    (job) =>
      job.forwardSignedDocument?.status ===
      ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED
  );
  const showsPublicUpsale = jobs.some(
    (job) =>
      job.forwardSignedDocument?.status ===
      ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_PUBLIC_MAILBOX_BUT_IS_NOT_REGISTERED
  );
  if (showsDigipostUpsale && showsPublicUpsale) {
    return completedJobsUpsaleState.SHOWS_BOTH_DIGIPOST_AND_PUBLIC_UPSALES;
  } else if (showsDigipostUpsale) {
    return completedJobsUpsaleState.SHOWS_DIGIPOST_UPSALE;
  } else if (showsPublicUpsale) {
    return completedJobsUpsaleState.SHOWS_PUBLIC_UPSALE;
  }
  return completedJobsUpsaleState.SHOWS_NO_UPSALE;
};
