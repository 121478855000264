import { FunctionComponent } from 'react';
import { linkToInternalSite } from '../navigation/externalNavigation';
import { useI18n, withParams } from '../language/i18n';
import HelpContent from './HelpContent';

const VALIDITY_ID = 'gyldighet';

const SendHelp: FunctionComponent = () => {
  const [translation] = useI18n();
  return (
    <>
      <HelpContent
        title={translation.common.help.topics.send.sub_heading_1}
        helpSubTopics={[
          {
            name: 'fødselsnummer',
            headingTextKey: translation.common.help.topics.send.pin_heading,
            bodyTextKeys: [
              translation.common.help.topics.send.pin_persons_body_p3,
              translation.common.help.topics.send.pin_body_p1,
              translation.common.help.topics.send.pin_body_p2,
              translation.common.help.topics.send.pin_body_p3,
            ],
          },
          {
            name: 'automatisk-signert',
            headingTextKey:
              translation.common.help.topics.send.will_sender_sign_header,
            bodyTextKeys: [
              translation.common.help.topics.send.will_sender_sign_body,
            ],
          },
        ]}
      />
      <HelpContent
        title={translation.common.help.topics.send.sub_heading_2}
        helpSubTopics={[
          {
            name: 'etter-sending',
            headingTextKey:
              translation.common.help.topics.send.after_created_heading,
            bodyTextKeys: [
              translation.common.help.topics.send.after_created_body_p1,
            ],
          },
          {
            name: 'endre-dokumenter',
            headingTextKey:
              translation.common.help.topics.send.edit_request_header,
            bodyTextKeys: [
              translation.common.help.topics.send.edit_request_body,
            ],
          },
          {
            name: 'endre-frist',
            headingTextKey:
              translation.common.help.topics.send.edit_deadline_header,
            bodyTextKeys: [
              translation.common.help.topics.send.edit_deadline_persons_body,
              translation.common.help.topics.send.edit_deadline_body,
            ],
          },
          {
            name: 'avviser',
            headingTextKey: translation.common.help.topics.send.reject_heading,
            bodyTextKeys: [translation.common.help.topics.send.reject_body],
          },
          {
            name: 'mangler-invitasjon',
            headingTextKey:
              translation.common.help.topics.send.missing_invitation_header,
            bodyTextKeys: [
              translation.common.help.topics.send
                .missing_invitation_persons_body_1,
              translation.common.help.topics.send
                .missing_invitation_persons_body_2,
              translation.common.help.topics.send
                .missing_invitation_persons_body_3,
              withParams(
                translation.common.help.topics.send.missing_invitation_body,
                { url: linkToInternalSite('/logginn').href }
              ),
            ],
          },
        ]}
      />
      <HelpContent
        title={translation.common.help.topics.send.sub_heading_3}
        helpSubTopics={[
          {
            name: 'mangler-signatur',
            headingTextKey:
              translation.common.help.topics.sign.missing_signature_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.missing_signature_body_p1,
              translation.common.help.topics.sign.missing_signature_body_p2,
              translation.common.help.topics.sign.missing_signature_body_p3,
            ],
          },
          {
            name: 'sendte-dokumenter',
            headingTextKey:
              translation.common.help.topics.send.find_sent_requests_header,
            bodyTextKeys: [
              translation.common.help.topics.send
                .find_sent_requests_persons_body,
              translation.common.help.topics.send.find_sent_requests_body,
            ],
          },
          {
            name: 'tilgang-signert',
            headingTextKey:
              translation.common.help.topics.send.find_signed_document_heading,
            bodyTextKeys: [
              translation.common.help.topics.send
                .find_signed_document_persons_body_1,
              translation.common.help.topics.send
                .find_signed_document_persons_body_2,
              translation.common.help.topics.send
                .find_signed_document_persons_body_3,
              translation.common.help.topics.send.find_signed_document_body,
            ],
          },
          {
            name: 'digital-signatur',
            headingTextKey: translation.common.help.topics.sign.what_is_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.what_is_body_p1,
              translation.common.help.topics.sign.what_is_body_p2,
              translation.common.help.topics.sign.what_is_body_p3,
            ],
          },
          {
            name: VALIDITY_ID,
            headingTextKey:
              translation.common.help.topics.archive_and_validation
                .validation_heading,
            bodyTextKeys: [
              translation.common.help.topics.archive_and_validation
                .validation_body_visual_1,
              translation.common.help.topics.archive_and_validation
                .validation_body_visual_2,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p1,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p2,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p3,
            ],
          },
          {
            name: 'printing',
            headingTextKey:
              translation.common.help.topics.archive_and_validation.print
                .heading,
            bodyTextKeys: [
              withParams(
                translation.common.help.topics.archive_and_validation.print
                  .body,
                { validityHref: `#${VALIDITY_ID}` }
              ),
            ],
          },
        ]}
      />
      <HelpContent
        title={translation.common.help.topics.send.sub_heading_4}
        helpSubTopics={[
          {
            name: 'refusjon',
            headingTextKey: translation.common.help.topics.send.refund_heading,
            bodyTextKeys: [
              translation.common.help.topics.send.refund_body_1,
              translation.common.help.topics.send.refund_body_list,
              translation.common.help.topics.send.refund_body_2,
            ],
          },
          {
            name: 'refusjon-steg',
            headingTextKey:
              translation.common.help.topics.send.request_refund_heading,
            bodyTextKeys: [
              translation.common.help.topics.send.request_refund_body_1,
              translation.common.help.topics.send.request_refund_body_list,
              translation.common.help.topics.send.request_refund_body_2,
            ],
          },
        ]}
      />
    </>
  );
};

export default SendHelp;
