import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Curtain from '@hedwig/curtain/Curtain';
import { IconSet, IconSize, LinkIcon } from '@hedwig/icons';
import { useTracking } from '../analytics/Analytics';
import FocusableElement from '../focus/FocusableElement';
import { useFocus } from '../focus/AppWithFocus';

interface HelpListItemProps {
  bodyTextKeys: Array<string>;
  headingText: string;
  subTopic: string;
  id: string;
}

const HelpListItem: FunctionComponent<HelpListItemProps> = ({
  subTopic,
  headingText,
  bodyTextKeys,
  id,
}) => {
  const { registerAnalyticsEvent } = useTracking();
  const { setFocus } = useFocus();
  const location = useLocation();
  useEffect(() => {
    if (location.hash.includes(id)) {
      setFocus(`${id}-wrapper`, true);
    }
  }, [id, location.hash, setFocus]);

  return (
    <FocusableElement id={`${id}-wrapper`} className="help-list__item-wrapper">
      <a href={`#${id}`} className="help-list__link icon-with-text">
        <LinkIcon size={IconSize.SMALL} set={IconSet.REGULAR} />
      </a>
      <Curtain
        id={id}
        expandCurtain={location.hash.includes(id)}
        text={headingText}
        triggerClassName="help-curtain__trigger"
        textClassName="help-curtain__heading"
        buttonId={`button-help-${subTopic}`}
        logToAnalyticsOnClick={() =>
          registerAnalyticsEvent({
            category: 'Landingsside',
            action: 'Åpnet spørsmål på hjelpesiden',
            label: subTopic,
          })
        }
      >
        <div>
          {bodyTextKeys.map((textKey: string) => (
            <p
              className="hw-text-small"
              key={textKey}
              dangerouslySetInnerHTML={{ __html: textKey }}
            />
          ))}
        </div>
      </Curtain>
    </FocusableElement>
  );
};

export default HelpListItem;
