import { FunctionComponent, useCallback, useEffect } from 'react';

import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import { addClassesFromProps } from '@common/basicFunctions';
import { Size, Width, sizes, widths } from '@hedwig/buttons/buttonAttributes';
import { Button } from '@hedwig/buttons';
import { useI18n } from '@common/language/i18n';
import { ExclamationTriangleIcon, SuccessIcon } from '@hedwig/icons';
import { useTracking } from '@common/analytics/Analytics';
import { patch, useSigneringMutationApi } from '@common/api/signeringApi';
import { ForwardSignedDocumentStatus } from '../../../forwarding/forwardSignedDocumentStatus';
import { ApiRequestStatus } from '@common/api/useApi';

import digipost from './digipost.svg';
import digipostDark from './digipost-dark-gray.svg';
import './forward-signed-document.less';

const forwardSignedDocument = (forwardLink: string) =>
  patch(
    forwardLink,
    JSON.stringify({
      status: ForwardSignedDocumentStatus.WILL_BE_FORWARDED_TO_DIGIPOST,
    })
  );

interface ForwardSignedDocumentToDigipostButtonProps {
  forwardLink?: string;
  text: string;
  width?: Width;
  size?: Size;
  dataTestid?: string;
  extraClasses?: string;
  view: string;
  extraButtonTextClasses?: string;
}

const ForwardSignedDocumentToDigipostButton: FunctionComponent<
  ForwardSignedDocumentToDigipostButtonProps
> = ({
  text,
  forwardLink,
  size,
  width,
  dataTestid,
  extraClasses,
  view,
  extraButtonTextClasses,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const { request, mutate } = useSigneringMutationApi(forwardSignedDocument);
  const disabled = request.status === ApiRequestStatus.SUCCESS || !forwardLink;

  const forwardDocumentOnClick = useCallback(() => {
    forwardLink && mutate(forwardLink);
  }, [forwardLink, mutate]);

  const commonProps = {
    size: size ? size : sizes.medium,
    width: width ? width : widths.normal,
    dataTestid: dataTestid,
    onClick: forwardDocumentOnClick,
  };

  useEffect(() => {
    registerAnalyticsEvent({
      category: 'Videresending til Digipost - ' + view,
      action: 'Knapp for videresending til Digipost vist til bruker',
      label: '',
    });
  }, [view, registerAnalyticsEvent]);

  useEffect(() => {
    if (
      request.status === ApiRequestStatus.SUCCESS ||
      request.status === ApiRequestStatus.FAILURE
    ) {
      registerAnalyticsEvent({
        category: 'Videresending til Digipost - ' + view,
        action: 'Bruker trykket på knapp for videresending til Digipost',
        label:
          request.status === ApiRequestStatus.SUCCESS
            ? 'Videresending vellykket'
            : request.status === ApiRequestStatus.FAILURE
            ? 'Videresending feilet'
            : undefined,
      });
    }
  }, [view, request, registerAnalyticsEvent]);

  return request.status === ApiRequestStatus.PENDING ? (
    <Button
      {...commonProps}
      extraClassName={`spinner-button${addClassesFromProps(extraClasses)}`}
      disabled={true}
      ariaLabel={text}
    >
      <SpinnerHW />
    </Button>
  ) : (
    <>
      <Button
        {...commonProps}
        extraClassName={extraClasses}
        disabled={disabled}
      >
        {disabled ? (
          <img
            src={digipostDark}
            className="digipost-icon button-icon-with-text"
            alt="Logo: Digipost"
            aria-hidden={true}
          />
        ) : (
          <img
            src={digipost}
            className="digipost-icon button-icon-with-text"
            alt="Logo: Digipost"
            aria-hidden={true}
          />
        )}
        {text}
      </Button>
      {request.status === ApiRequestStatus.SUCCESS && (
        <p
          className={`hw-block--pt-small-2${addClassesFromProps(
            extraButtonTextClasses
          )}`}
          aria-live="polite"
        >
          <SuccessIcon extraClassName="icon-with-text" />
          {translation.portal.log.accessible.forwarded_to_digipost}
        </p>
      )}
      {request.status === ApiRequestStatus.FAILURE && (
        <p
          className={`hw-block--pt-small-2${addClassesFromProps(
            extraButtonTextClasses
          )}`}
          aria-live="polite"
        >
          <ExclamationTriangleIcon extraClassName="icon-with-text" />
          {translation.portal.log.accessible.forwarding_to_digipost_failed}
        </p>
      )}
    </>
  );
};

export default ForwardSignedDocumentToDigipostButton;
