import { Fragment, FunctionComponent } from 'react';
import HelpListItem from './HelpListItem';
import '@common/lists/lists.less';

interface HelpContentProps {
  helpSubTopics: Array<HelpSubTopic>;
  title: string;
}

interface HelpSubTopic {
  headingTextKey: string;
  bodyTextKeys: Array<string>;
  name: string;
}

const HelpContent: FunctionComponent<HelpContentProps> = ({
  helpSubTopics,
  title,
}) => {
  return (
    <Fragment>
      <h2 className="hw-text-title hw-h3 hw-block--mb-medium-1 help-subheading">
        {title}
      </h2>
      <ul className="help-list help-question-list hw-list--no-bullets">
        {helpSubTopics.map((subTopic) => (
          <li key={subTopic.name}>
            <HelpListItem
              id={subTopic.name}
              subTopic={subTopic.name}
              key={subTopic.headingTextKey}
              headingText={subTopic.headingTextKey}
              bodyTextKeys={subTopic.bodyTextKeys}
            />
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default HelpContent;
