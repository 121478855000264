import { FunctionComponent } from 'react';

import { SecurityLevel } from '@common/idporten/securityLevel';
import { useI18n } from '@common/language/i18n';
import CompletedJobRow from './CompletedJobRow';
import { RecentlySignedJob } from './recentlySignedJobs';

const CompletedJobsTable: FunctionComponent<CompletedJobsTableProps> = ({
  jobs,
  authenticatedSecurityLevel,
}) => {
  const [translation] = useI18n();

  return (
    <table className="hw-table signed-jobs__table">
      <thead className="ui-max ui-min-screen-reader-only">
        <tr>
          <th className="signed-jobs__table-col-doc">
            {translation.portal.log.from}
          </th>
          <th className="signed-jobs__table-col-signed-time">
            {translation.portal.log.signed_at}
          </th>
          <th className="signed-jobs__table-col-forwarded">
            {translation.portal.log.status_header}
          </th>
          <th className="signed-jobs__table-col-download">
            {translation.portal.log.download}
          </th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <CompletedJobRow
            job={job}
            key={job.id}
            authLevel={authenticatedSecurityLevel}
          />
        ))}
      </tbody>
    </table>
  );
};

interface CompletedJobsTableProps {
  authenticatedSecurityLevel: SecurityLevel;
  jobs: RecentlySignedJob[];
}

export default CompletedJobsTable;
