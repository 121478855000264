import { FunctionComponent, useEffect } from 'react';
import DOMPurify from 'dompurify';

import { useTracking } from '@common/analytics/Analytics';
import { Translation, useI18n, withParams } from '@common/language/i18n';
import { ErrorIcon } from '@hedwig/icons';
import { App } from '../../../../apps';
import { FailedSignatureJob, FailureReason } from './failedSignatureJobs';

import './failed-jobs.less';

const getTextWithReason = (
  reason: FailureReason,
  translation: Translation['portal']['list']['failed']
) => {
  switch (reason) {
    case FailureReason.EXPIRED:
      return translation.expired;
    case FailureReason.REJECTED:
      return translation.rejected;
    case FailureReason.CANCELLED:
      return translation.cancelled;
  }
};

const NewlyFailedJobMessage: FunctionComponent<NewlyFailedJobMessageProps> = ({
  failedSignatureJobs,
}) => {
  const { registerAnalyticsEvent } = useTracking();

  useEffect(() => {
    registerAnalyticsEvent({
      category: App.SIGNATURE_PORTAL,
      action: 'Info om nylig feilet jobb vist',
      label: failedSignatureJobs.map((job) => job.reason).join(),
    });
  }, [failedSignatureJobs, registerAnalyticsEvent]);

  const [translation, , format] = useI18n();

  return (
    <div
      className="failed-info-box hw-block--mt-medium-4"
      data-testid="failed-signature-jobs-list"
    >
      <ul className="hw-list--no-bullets">
        {failedSignatureJobs.map((job) => {
          return (
            <li key={job.id} className="hw-block--mt-medium-2">
              <ErrorIcon extraClassName="icon-with-text" />
              <span
                dangerouslySetInnerHTML={{
                  __html: withParams(
                    getTextWithReason(
                      job.reason,
                      translation.portal.list.failed
                    ),
                    {
                      sender: DOMPurify.sanitize(job.sender),
                      subject: DOMPurify.sanitize(job.subject),
                      date: format(job.eventTime, 'dd.MM.yyyy, HH:mm'),
                    }
                  ),
                }}
              ></span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface NewlyFailedJobMessageProps {
  failedSignatureJobs: Array<FailedSignatureJob>;
}

export default NewlyFailedJobMessage;
