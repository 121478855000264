import { FunctionComponent, useState, MouseEvent, useMemo } from 'react';

import { JobTitleAndLogo } from '@common/unsignedJob/JobTitleAndLogo';
import UnsignedJobButtons from '@common/unsignedJob/UnsignedJobButtons';
import JobSender from '@common/unsignedJob/JobSender';
import JobInformation from '@common/unsignedJob/JobInformation';
import LogUpActions from './LogUpActions';
import RejectJobAction from '@common/unsignedJob/RejectJobAction';
import { App } from '../../../apps';
import { UnsignedSignatureJob } from '../unsignedSignatureJob';
import { useSession } from '@common/session/AppWithSession';
import { hasValidSecurityLevel } from '@common/idporten/securityLevel';
import signingUrlFrom from '@common/signing/signingUrlFrom';
import { useUnsignedSignatureJobs } from './UnsignedJobs';
import { AppName } from '@common/analytics/appNames';

import '@common/unsignedJob/unsigned-job.less';

const UnsignedJob: FunctionComponent<UnsignedJobProps> = ({
  signatureJob,
  setFocus,
  app,
}) => {
  const { session } = useSession();
  const { refetch } = useUnsignedSignatureJobs();

  const [rejectWarningVisible, setRejectWarningVisible] = useState(false);

  const userHasAccessToJob = useMemo(
    () =>
      hasValidSecurityLevel(signatureJob.securityLevel, session.securityLevel),
    [session, signatureJob.securityLevel]
  );
  const startSigningUrl = useMemo(
    () =>
      signingUrlFrom({
        jobId: signatureJob.id,
        signatureType: signatureJob.signatureType,
      }),
    [signatureJob.id, signatureJob.signatureType]
  );

  return (
    <>
      <JobTitleAndLogo
        title={signatureJob.subject}
        logoURL={signatureJob.links.senderLogo}
      />
      <JobSender
        sender={{ name: signatureJob.sender }}
        extraClassName="hw-block--pt-medium-2"
      />
      {!userHasAccessToJob && (
        <LogUpActions
          sector={signatureJob.sector}
          id={signatureJob.id}
          sender={signatureJob.sender}
          extraClassName="logup--warning hw-block--mt-medium-2"
        />
      )}
      {rejectWarningVisible ? (
        <RejectJobAction
          sender={{ name: signatureJob.sender }}
          onCancel={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setRejectWarningVisible(false);
          }}
          setFocus={setFocus}
          links={signatureJob.links}
          onSuccess={refetch}
          appName={AppName.SIGNER}
        />
      ) : (
        userHasAccessToJob && (
          <>
            <JobInformation
              message={signatureJob.message}
              onBehalfOf={signatureJob.onBehalfOf}
              expirationTime={signatureJob.expirationTime}
              numberOfSigners={signatureJob.numberOfSigners}
              numberOfSuccessfulSignatures={
                signatureJob.numberOfSuccessfulSignatures
              }
              documents={signatureJob.documents}
            />
            <UnsignedJobButtons
              id={signatureJob.id}
              sender={{ name: signatureJob.sender }}
              setFocus={setFocus}
              subject={signatureJob.subject}
              setRejectWarningVisible={setRejectWarningVisible}
              startSigningUrl={startSigningUrl}
              app={app}
            />
          </>
        )
      )}
    </>
  );
};

interface UnsignedJobProps {
  setFocus: (id: string) => void;
  signatureJob: UnsignedSignatureJob;
  app: App;
}

export default UnsignedJob;
