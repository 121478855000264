import { FunctionComponent, useMemo } from 'react';

import UnsignedJobs, {
  useUnsignedSignatureJobs,
} from './unsignedJobs/UnsignedJobs';
import CompletedJobs from './CompletedJobs';
import {
  getUserTypeFromSurveyCookie,
  ShortUserType,
} from '@common/popupSurvey/satisfactionSurvey';
import PageContainer from '@common/pageBuildingBlocks/PageContainer';
import { AppName } from '@common/analytics/appNames';
import { OrganizationSector } from '@common/organizations/organizationSector';
import PopupSurveyContainer from '@common/popupSurvey/PopupSurveyContainer';
import { useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus, isLoading } from '@common/api/useApi';
import { fetchRecentlySignedJobs } from './completedJobs/recentlySignedJobs';
import { fetchChoosableOrganizationsForTrustee } from '../../organization/choose/choosableOrganizationForTrustee';
import { ChevronRightIcon, IconSize } from '@hedwig/icons';
import { useTracking } from '@common/analytics/Analytics';
import { useI18n } from '@common/language/i18n';
import Spinner from '@common/basics/Spinner';
import ErrorMessage from '@common/basics/ErrorMessage';
import FocusableElement from '@common/focus/FocusableElement';
import Heading from '@common/pageBuildingBlocks/Heading';

export const unsignedjobListHeading = 'unsignedjob-list-heading';

const SignatureJobsWrapper: FunctionComponent = () => {
  const { registerAnalyticsEvent } = useTracking();
  const [translation] = useI18n();
  const { request: unsignedSignatureJobRequest } = useUnsignedSignatureJobs();
  const { request: recentlySignedJobsRequest } = useSigneringApi(
    fetchRecentlySignedJobs
  );
  const sector: OrganizationSector | undefined = useMemo(() => {
    if (
      unsignedSignatureJobRequest.status === ApiRequestStatus.SUCCESS &&
      unsignedSignatureJobRequest.result.length > 0
    ) {
      return unsignedSignatureJobRequest.result[0].sector;
    } else if (
      recentlySignedJobsRequest.status === ApiRequestStatus.SUCCESS &&
      recentlySignedJobsRequest.result.length > 0
    ) {
      return recentlySignedJobsRequest.result[0].sector;
    } else {
      return undefined;
    }
  }, [unsignedSignatureJobRequest, recentlySignedJobsRequest]);

  const { request: orgRequest } = useSigneringApi(
    fetchChoosableOrganizationsForTrustee
  );
  const accessToOrganizations =
    orgRequest.status === ApiRequestStatus.SUCCESS
      ? orgRequest.result.length
      : undefined;

  if (
    (isLoading(recentlySignedJobsRequest.status) &&
      isLoading(unsignedSignatureJobRequest.status)) ||
    isLoading(orgRequest.status)
  ) {
    return (
      <PageContainer>
        <Spinner fullScreen={true} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {!!accessToOrganizations && accessToOrganizations > 0 && (
        <div className="hw-block--mb-large-2">
          <a
            href="/virksomhet"
            className="hw-link"
            onClick={() => {
              registerAnalyticsEvent({
                category: AppName.SIGNER,
                action: 'Klikket på gå til avsenderportal',
                label: 'Via lenke i undertegnerportal',
              });
            }}
          >
            {accessToOrganizations > 1
              ? translation.portal.link.go_to_organization.plural
              : translation.portal.link.go_to_organization.singular}
          </a>
          <ChevronRightIcon size={IconSize.SMALL} />
        </div>
      )}
      <section>
        <FocusableElement id={unsignedjobListHeading}>
          <Heading
            headerText={translation.portal.list.header}
            extraClassName="hw-block--mb-medium-3"
          />
        </FocusableElement>
        {recentlySignedJobsRequest.status === ApiRequestStatus.FAILURE &&
        unsignedSignatureJobRequest.status === ApiRequestStatus.FAILURE ? (
          <ErrorMessage />
        ) : (
          <>
            <UnsignedJobs />
            <CompletedJobs />
          </>
        )}
      </section>

      <PopupSurveyContainer
        showAfterMs={30000}
        context="undertegnerportal - signeringsoversikt"
        userType={getUserTypeFromSurveyCookie() || ShortUserType.SIGNER}
        product="virksomhet"
        sector={sector}
      />
    </PageContainer>
  );
};

export default SignatureJobsWrapper;
