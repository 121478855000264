import { FunctionComponent } from 'react';

import UnsignedJob from './UnsignedJob';
import { UnsignedSignatureJob } from '../unsignedSignatureJob';
import { App } from '../../../apps';
import NoUnsignedJobs from './noUnsignedJobs/NoUnsignedJobs';
import { useFocus } from '@common/focus/AppWithFocus';

import './unsigned-jobs.less';

const UnsignedJobList: FunctionComponent<UnsignedJobListProps> = ({
  signatureJobs,
}) => {
  const { setFocus } = useFocus();

  return signatureJobs.length === 0 ? (
    <NoUnsignedJobs />
  ) : (
    <ul className="hw-list--no-bullets">
      {signatureJobs.map((job) => {
        return (
          <li
            key={job.id}
            id={`signature-job-element-${job.id}`}
            className="unsigned-job"
          >
            <UnsignedJob
              signatureJob={job}
              setFocus={setFocus}
              app={App.SIGNATURE_PORTAL}
            />
          </li>
        );
      })}
    </ul>
  );
};

interface UnsignedJobListProps {
  signatureJobs: Array<UnsignedSignatureJob>;
}

export default UnsignedJobList;
