import { FunctionComponent, useMemo } from 'react';

import { useI18n, withParams } from '../language/i18n';
import InformationContainer from '../informationContainer/InformationContainer';
import { DateTime } from '../language/DateTime';
import JobDocuments from './JobDocuments';
import { JobDocument } from '../jobs/jobDocuments/JobDocumentList';
import { OnBehalfOf } from '../signing/onBehalfOf';

const JobInformation: FunctionComponent<JobInformationProps> = ({
  documents,
  message,
  onBehalfOf,
  expirationTime,
  numberOfSigners,
  numberOfSuccessfulSignatures,
}) => {
  const [translation] = useI18n();

  const showDocumentsOnLeft = useMemo(
    () => !message && onBehalfOf === OnBehalfOf.SELF,
    [message, onBehalfOf]
  );

  return (
    <div
      className={`unsigned-job__columns ${
        showDocumentsOnLeft ? 'unsigned-job__columns--reverse' : ''
      }`}
    >
      <div className="unsigned-job__column unsigned-job__column--1">
        {message && <p className="hw-block--pt-small-2">{message}</p>}
        {showDocumentsOnLeft && <JobDocuments documents={documents} />}
        {onBehalfOf === OnBehalfOf.OTHER && (
          <InformationContainer extraClassName="hw-block--mt-small-4 hw-block--pb-small-4 hw-block--pt-small-4">
            <p className="hw-text-small">
              {translation.common.sign_on_behalf_of}
            </p>
          </InformationContainer>
        )}
      </div>
      <div className="unsigned-job__column unsigned-job__column--2">
        <p className="hw-block--pt-small-2 hw-block--mb-small-2">
          <span
            dangerouslySetInnerHTML={{
              __html: translation.portal.list.expiration_time,
            }}
          />{' '}
          <DateTime date={expirationTime} />
        </p>
        <p
          className="hw-block--mb-small-4"
          dangerouslySetInnerHTML={{
            __html: withParams(translation.portal.list.status, {
              signers: numberOfSigners,
              signed: numberOfSuccessfulSignatures,
            }),
          }}
        />
        {!showDocumentsOnLeft && <JobDocuments documents={documents} />}
      </div>
    </div>
  );
};

interface JobInformationProps {
  message?: string;
  onBehalfOf?: string;
  expirationTime: Date;
  numberOfSigners: number;
  numberOfSuccessfulSignatures: number;
  documents: Array<JobDocument>;
}

export default JobInformation;
