import { FunctionComponent } from 'react';

import { addClassesFromProps } from '../basicFunctions';
import { useI18n, withParams } from '../language/i18n';
import { OpenPortalJobSender } from '../../openPortal/jobs/openPortalSignatureJob';

const JobSender: FunctionComponent<JobSenderProps> = ({
  sender,
  extraClassName,
}) => {
  const [translation] = useI18n();

  return (
    <span
      className={`hw-text-title display-block${addClassesFromProps(
        extraClassName
      )}`}
      data-testid="sender-name"
    >
      {withParams(translation.common.signaturejob.sender_info, {
        sender: sender.name,
      })}
    </span>
  );
};

interface JobSenderProps {
  sender: Pick<OpenPortalJobSender, 'name'>;
  extraClassName?: string;
}

export default JobSender;
