import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { DateTime } from '@common/language/DateTime';
import ForwardingInfo from './ForwardingInfo';
import {
  hasValidSecurityLevel,
  SecurityLevel,
} from '@common/idporten/securityLevel';
import ForwardSignedDocumentToDigipost from './forwardSignedDocumentToDigipost/ForwardSignedDocumentToDigipost';
import { ForwardSignedDocumentStatus } from '../../forwarding/forwardSignedDocumentStatus';
import { useI18n, withParams } from '@common/language/i18n';
import { buttonStyles, DownloadDocumentButton, widths } from '@hedwig/buttons';
import { addDuration, TimeUnit } from '@common/time/time';
import LogUpActions from './LogUpActions';
import { AppName } from '@common/analytics/appNames';
import MobileOnlyHeaderInColumn from '@common/table/MobileOnlyHeaderInColumn';
import { ErrorIcon, ExclamationTriangleIcon, IconColor } from '@hedwig/icons';
import {
  fetchRecentlySignedJob,
  RecentlySignedJob,
} from './recentlySignedJobs';
import { useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus } from '@common/api/useApi';
import { PadesStatus } from '@common/jobs/pades';

import './signed-jobs.less';

const CompletedJobRow: FunctionComponent<CompletedJobRowProps> = ({
  job,
  authLevel,
}) => {
  const [translation, , format] = useI18n();
  const [padesDownloadUriRefetchFailed, setPadesDownloadUriRefetchFailed] =
    useState(false);
  const [padesDownloadUriNotReady, setPadesDownloadUriNotReady] = useState(
    job.jobCompletedAt &&
      !job.pades?.link &&
      job.pades?.status !== PadesStatus.DELETED_BY_SENDER
  );

  const { request } = useSigneringApi({
    dataFetcher: fetchRecentlySignedJob,
    urlArgs: [job.id],
    options: {
      enabled: !!padesDownloadUriNotReady,
      refetchInterval: (_, query) => {
        if (query.state.dataUpdateCount < 6) {
          return (
            1000 * (query.state.dataUpdateCount * query.state.dataUpdateCount)
          );
        } else {
          setPadesDownloadUriRefetchFailed(true);
          return false;
        }
      },
    },
  });

  const pades = useMemo(
    () =>
      request.status === ApiRequestStatus.SUCCESS
        ? request.result.pades
        : job.pades,
    [request, job.pades]
  );

  useEffect(() => {
    if (job.jobCompletedAt && pades?.link) {
      setPadesDownloadUriNotReady(false);
    }
  }, [job.jobCompletedAt, pades]);

  const expirationDate = useMemo(
    () =>
      job.jobCompletedAt
        ? format(
            addDuration(TimeUnit.DAY, 40, new Date(job.jobCompletedAt)),
            'dd.MM.yyyy'
          )
        : '',
    [job.jobCompletedAt, format]
  );

  return (
    <tr
      key={job.id}
      id={`completed-signature-job-${job.id}`}
      className="signed-jobs__table-row"
    >
      <td className="signed-jobs__table-cell signed-jobs-table__cell--doc">
        <MobileOnlyHeaderInColumn extraClassName="column-header__doc">
          {translation.portal.log.from}
        </MobileOnlyHeaderInColumn>
        <div>
          <span className="hw-text-title display-block hw-color-primary-dark">{`${
            job.sender
          }${job.subject ? ': ' : ''}`}</span>
          {job.subject}
        </div>
      </td>
      <td className="signed-jobs__table-cell signed-jobs-table__cell--signed-time">
        <MobileOnlyHeaderInColumn>
          {translation.portal.log.signed_at}
        </MobileOnlyHeaderInColumn>
        <DateTime date={job.signedAt} />
      </td>
      <td className="signed-jobs__table-cell signed-jobs-table__cell--forwarded">
        <MobileOnlyHeaderInColumn extraClassName="column-header__forwarded">
          {translation.portal.log.status_header}
        </MobileOnlyHeaderInColumn>
        <ForwardingInfo
          numberOfSigners={job.numberOfSigners}
          numberOfSignedSigners={job.numberOfSignedSigners}
        />
      </td>
      <td className="signed-jobs-table__cell--download">
        {hasValidSecurityLevel(job.securityLevel, authLevel) ? (
          <>
            {job.forwardSignedDocument?.status ===
              ForwardSignedDocumentStatus.CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST &&
              job.jobCompletedAt && (
                <ForwardSignedDocumentToDigipost
                  job={job}
                  authLevel={authLevel}
                />
              )}
            <div className="table-cell-value__download">
              {job.jobCompletedAt &&
                !padesDownloadUriRefetchFailed &&
                pades?.status !== PadesStatus.DELETED_BY_SENDER && (
                  <DownloadDocumentButton
                    extraClasses="save-document__button-and-text"
                    downloadLink={pades?.link}
                    text={translation.portal.log.download_signed.mobile_button}
                    width={widths.responsive}
                    disabled={pades?.status !== PadesStatus.AVAILABLE_UNTIL}
                    buttonStyle={buttonStyles['secondary-outlined']}
                    iconColor={IconColor.PRIMARY_DARKER}
                    category={AppName.SIGNER}
                    analyticsLabel="Tabell i undertegnerportal"
                  />
                )}
              {job.jobCompletedAt ? (
                pades?.status === PadesStatus.DELETED_BY_SENDER ? (
                  <p>
                    <ExclamationTriangleIcon extraClassName="icon-with-text" />
                    {translation.portal.log.accessible.document_deleted}
                  </p>
                ) : !pades?.link && padesDownloadUriRefetchFailed ? (
                  <p>
                    <ExclamationTriangleIcon extraClassName="icon-with-text" />
                    {translation.portal.signing.signed.failed}
                  </p>
                ) : !pades?.link ? (
                  translation.portal.signing.signed.refreshing
                ) : (
                  <span
                    id={`accessible-warning-text-${job.id}`}
                    className="hw-block--pt-small-2 save-document__button-and-text"
                    dangerouslySetInnerHTML={{
                      __html: withParams(
                        translation.portal.log.accessible.single,
                        {
                          date: expirationDate,
                        }
                      ),
                    }}
                  />
                )
              ) : (
                <span className="hw-block--pt-small-2 save-document__button-and-text">
                  <ErrorIcon extraClassName="icon-with-text" />
                  {translation.portal.log.accessible.document_not_available}
                </span>
              )}
            </div>
          </>
        ) : (
          <LogUpActions
            sector={job.sector}
            id={job.id}
            sender={job.sender}
            extraClassName="signed-jobs-table__cell--logup-warning"
          />
        )}
      </td>
    </tr>
  );
};

interface CompletedJobRowProps {
  job: RecentlySignedJob;
  authLevel: SecurityLevel;
}

export default CompletedJobRow;
