import { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useI18n } from '../../language/i18n';
import { useSigneringApi } from '../../api/signeringApi';
import createSignicatIframeLink from '../../signicat/createSignicatIframeLink';
import { ApiRequestStatus } from '../../api/useApi';
import Spinner from '../../basics/Spinner';
import ErrorMessage from '../../basics/ErrorMessage';
import AuthenticatedSignatureDocument from './AuthenticatedSignatureDocument';

const AuthenticatedSignature: FunctionComponent<{
  createSignatureFrameUrl: string;
  cancelPath: string;
}> = ({ createSignatureFrameUrl, cancelPath }) => {
  const [translation, , , , language] = useI18n();
  const navigate = useNavigate();
  const { request } = useSigneringApi({
    dataFetcher: createSignicatIframeLink,
    urlArgs: [`${createSignatureFrameUrl}?eid=idporten&lang=${language}`],
    fetcherArgs: [window.location.href],
  });

  const cancel = useCallback(
    () => navigate(cancelPath),
    [cancelPath, navigate]
  );

  switch (request.status) {
    case ApiRequestStatus.SUCCESS:
      return (
        <AuthenticatedSignatureDocument
          cancelSignature={cancel}
          signicatIframeLink={request.result}
        />
      );
    case ApiRequestStatus.IDLE:
    case ApiRequestStatus.PENDING:
      return <Spinner fullScreen={true} />;
    case ApiRequestStatus.FAILURE: {
      return (
        <ErrorMessage message={translation.common.electronic_id.iframe_error} />
      );
    }
  }
};

export default AuthenticatedSignature;
