import { Fragment, FunctionComponent } from 'react';

import { OrganizationSector } from '@common/organizations/organizationSector';
import InformationContainer from '@common/informationContainer/InformationContainer';
import { buttonStyles, sizes, widths, LogOutButton } from '@hedwig/buttons';
import { addClassesFromProps } from '@common/basicFunctions';
import { useI18n, withParams } from '@common/language/i18n';

import '../completedJobs/signed-jobs.less';
import { PUBLIC_LOGIN_URL_LEVEL4 } from '../publicSectorLoginUrls';

interface LogUpActionsProps {
  sector: OrganizationSector;
  id: number;
  sender: string;
  extraClassName?: string;
}

const LogUpActions: FunctionComponent<LogUpActionsProps> = ({
  sector,
  id,
  sender,
  extraClassName,
}) => {
  const [translation] = useI18n();
  const isMobile = window.innerWidth <= 320;

  return (
    <InformationContainer
      extraClassName={`hw-block--pt hw-block--pb${addClassesFromProps(
        extraClassName
      )}`}
    >
      <h3 className="hw-text-title hw-text-small">
        {translation.portal.list.need_to_logup_title}
      </h3>
      <p
        className="hw-block--mt-medium-2"
        dangerouslySetInnerHTML={{
          __html: withParams(translation.portal.list.need_to_logup, { sender }),
        }}
      />
      {sector === OrganizationSector.PUBLIC ? (
        <a
          href={PUBLIC_LOGIN_URL_LEVEL4}
          data-testid={`start-signigning-logup-public-${id}`}
          id={`start-signing-button-${id}`}
          className="logup-actions__button hw-button hw-button--primary hw-button--small hw-button--mobile-full hw-block--mt-medium-2"
        >
          {isMobile ? (
            <span>{translation.portal.list.logup_mobile}</span>
          ) : (
            <span>{translation.portal.list.logup}</span>
          )}
        </a>
      ) : (
        <Fragment>
          <p className="hw-block--mt-medium-2">
            {translation.portal.list.need_to_logout}
          </p>
          <LogOutButton
            buttonStyle={buttonStyles.primary}
            size={sizes.small}
            width={widths.responsive}
            extraClassName="hw-block--mt-medium-2"
          />
        </Fragment>
      )}
    </InformationContainer>
  );
};

export default LogUpActions;
