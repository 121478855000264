import { FunctionComponent } from 'react';

import { PartialSuccessIcon, SuccessIcon } from '@hedwig/icons';
import '@hedwig/icons/icons.less';
import { useI18n, withParams } from '@common/language/i18n';

const ForwardingInfo: FunctionComponent<ForwardingInfoProps> = ({
  numberOfSigners,
  numberOfSignedSigners,
}) => {
  const [translation] = useI18n();

  return (
    <div className="column-value illustrated-text illustrated-text--top-aligned">
      {numberOfSigners === numberOfSignedSigners ? (
        <SuccessIcon extraClassName="icon-with-text" />
      ) : (
        <PartialSuccessIcon extraClassName="icon-with-text" />
      )}
      <p className="forwarding-text">
        {withParams(translation.portal.signing.signed.status, {
          signers: numberOfSigners,
          signed: numberOfSignedSigners,
        })}
      </p>
    </div>
  );
};

interface ForwardingInfoProps {
  numberOfSigners: number;
  numberOfSignedSigners: number;
}

export default ForwardingInfo;
