import { FunctionComponent } from 'react';

import UnsignedJobList from './UnsignedJobList';
import Spinner from '@common/basics/Spinner';
import ErrorMessage from '@common/basics/ErrorMessage';
import { useSigneringApi } from '@common/api/signeringApi';
import { fetchUnsignedSignatureJobs } from '../unsignedSignatureJob';
import { ApiRequestStatus, isLoading } from '@common/api/useApi';
import { useI18n, withParams } from '@common/language/i18n';

const UnsignedJobs: FunctionComponent = () => {
  const [translation] = useI18n();
  const { request } = useUnsignedSignatureJobs();

  return (
    <>
      <h2 className="hw-h2 unsigned-jobs__heading">
        {request.status !== ApiRequestStatus.SUCCESS
          ? translation.portal.list.sub_header_loading
          : request.result.length > 0
          ? withParams(translation.portal.list.sub_header, {
              count: request.result.length,
            })
          : translation.portal.list.header_empty}
      </h2>
      {isLoading(request.status) && <Spinner />}
      {request.status === ApiRequestStatus.FAILURE && (
        <ErrorMessage message={translation.portal.list.loading_failed} />
      )}
      {request.status === ApiRequestStatus.SUCCESS && (
        <UnsignedJobList signatureJobs={request.result} />
      )}
    </>
  );
};

export const useUnsignedSignatureJobs = () =>
  useSigneringApi(fetchUnsignedSignatureJobs);

export default UnsignedJobs;
