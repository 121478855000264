import { FunctionComponent, useCallback, useEffect } from 'react';

import { useTracking } from '@common/analytics/Analytics';
import InformationContainer from '@common/informationContainer/InformationContainer';
import sleepingDogIllustration from '@images/signing/sleeping_dog.svg';
import { useI18n } from '@common/language/i18n';

const EmptyJobsListMessage: FunctionComponent = () => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  const trackEvent = useCallback(
    ({ eventAction }: { eventAction: string }) => {
      registerAnalyticsEvent({
        category: 'Signeringsportal - Innlogget',
        action: `Ingen signeringsforespørsler - ${eventAction}`,
      });
    },
    [registerAnalyticsEvent]
  );

  useEffect(() => {
    trackEvent({ eventAction: 'Informasjonsmelding vist' });
  }, [trackEvent]);

  return (
    <InformationContainer extraClassName="nothing-to-sign-message hw-flex">
      <img
        className="nothing-to-sign-message__illustration"
        src={sleepingDogIllustration}
        alt={translation.portal.list.empty.notification.illustration_label}
      />
      <div className="nothing-to-sign-message__content">
        <p className="hw-text-title hw-block--mb-small-4">
          {translation.portal.list.empty.notification.heading}
        </p>
        <p className="hw-block--mb-medium-3">
          {translation.portal.list.empty.notification.link_guide}
        </p>
        <p className="hw-block--mb-small-2">
          {
            translation.portal.list.empty.notification.body
              .difi_support_beginning_text
          }
        </p>
        <span
          dangerouslySetInnerHTML={{
            __html:
              translation.portal.list.empty.notification.body
                .difi_support_contact,
          }}
        />{' '}
        <a
          href="http://eid.difi.no/nb/kontakt-brukerstotte"
          className="hw-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackEvent({ eventAction: 'Klikket Digdir support link' })
          }
        >
          {
            translation.portal.list.empty.notification.body
              .difi_support_link_text
          }
        </a>
        <p className="hw-block--mb-small-2">
          {
            translation.portal.list.empty.notification.body
              .difi_support_end_text
          }
        </p>
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html:
                translation.portal.list.empty.notification.body
                  .digipost_support_beginning_text,
            }}
          />{' '}
          <a
            className="hw-link"
            href="mailto:kundeservice@digipost.no"
            onClick={() =>
              trackEvent({ eventAction: 'Klikket Digipost support link' })
            }
          >
            <span
              dangerouslySetInnerHTML={{
                __html:
                  translation.portal.list.empty.notification.body
                    .digipost_support_link_text,
              }}
            />
          </a>
        </p>
      </div>
    </InformationContainer>
  );
};

export default EmptyJobsListMessage;
