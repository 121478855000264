import {
  array,
  date,
  decodeType,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import { API_PORTAL_ROOT } from '@common/api/apiUrls';
import { maybeDecoder } from '@common/api/decoders';
import { createSigneringApiDataFetcher } from '@common/api/signeringApi';
import { organizationSectorDecoder } from '@common/organizations/organizationSector';
import { securityLevelDecoder } from '@common/idporten/securityLevel';
import { signatureTypeDecoder } from '@common/signing/signature';
import { onBehalfOfDecoder } from '@common/signing/onBehalfOf';

export type UnsignedSignatureJob = decodeType<
  typeof unsignedSignatureJobDecoder
>;
const unsignedSignatureJobDecoder = record({
  id: number,
  subject: maybeDecoder(string),
  message: maybeDecoder(string),
  created: date,
  expirationTime: date,
  numberOfSigners: number,
  numberOfSuccessfulSignatures: number,
  sender: string,
  links: record({
    self: string,
    createSignatureFrame: string,
    senderLogo: string,
  }),
  sector: organizationSectorDecoder,
  securityLevel: securityLevelDecoder,
  signatureType: signatureTypeDecoder,
  onBehalfOf: onBehalfOfDecoder,
  documents: array(record({ link: string, title: string })),
});

export const fetchUnsignedSignatureJob = createSigneringApiDataFetcher({
  name: 'UnsignedSignatureJob',
  url: (jobId: string) => `${API_PORTAL_ROOT}/signature-jobs/${jobId}`,
  decoder: unsignedSignatureJobDecoder,
});

export const fetchUnsignedSignatureJobs = createSigneringApiDataFetcher({
  name: 'UnsignedSignatureJobs',
  url: () => `${API_PORTAL_ROOT}/signature-jobs`,
  decoder: array(unsignedSignatureJobDecoder),
});
