import {
  array,
  date,
  DecoderFunction,
  decodeType,
  number,
  record,
  string,
  union,
} from 'typescript-json-decoder';

import { API_ROOT } from '@common/api/apiUrls';
import { createSigneringApiDataFetcher } from '@common/api/signeringApi';
import { organizationSectorDecoder } from '@common/organizations/organizationSector';

export enum FailureReason {
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}
const failureReasonDecoder: DecoderFunction<FailureReason> = (value) => {
  return union(...Object.values(FailureReason))(value);
};

export type FailedSignatureJob = decodeType<typeof failedSignatureJobDecoder>;
const failedSignatureJobDecoder = record({
  id: number,
  subject: string,
  expirationTime: date,
  sender: string,
  sector: organizationSectorDecoder,
  reason: failureReasonDecoder,
  eventTime: date,
});

export const fetchFailedSignatureJobs = createSigneringApiDataFetcher({
  name: 'FailedSignatureJobs',
  url: `${API_ROOT}/portal/signature-jobs/failed/`,
  decoder: array(failedSignatureJobDecoder),
});
