import { FunctionComponent } from 'react';

import { useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus } from '@common/api/useApi';
import ErrorMessage from '@common/basics/ErrorMessage';
import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import EmptyJobsListMessage from './EmptyJobsListMessage';
import { fetchFailedSignatureJobs } from './failedSignatureJobs';
import NewlyFailedJobMessage from './NewlyFailedJobMessage';

const NoUnsignedJobs: FunctionComponent = () => {
  const { request: failedSignatureJobsRequest } = useSigneringApi(
    fetchFailedSignatureJobs
  );

  switch (failedSignatureJobsRequest.status) {
    case ApiRequestStatus.IDLE:
    case ApiRequestStatus.PENDING:
      return (
        <div className="hw-block hw-block--px-fluid text-center">
          <SpinnerHW />
        </div>
      );
    case ApiRequestStatus.SUCCESS: {
      return failedSignatureJobsRequest.result.length > 0 ? (
        <NewlyFailedJobMessage
          failedSignatureJobs={failedSignatureJobsRequest.result}
        />
      ) : (
        <EmptyJobsListMessage />
      );
    }
    case ApiRequestStatus.FAILURE:
      return <ErrorMessage dataTestid="fetch-failed-signature-jobs-failure" />;
  }
};

export default NoUnsignedJobs;
