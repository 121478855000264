import { SignatureType } from './signature';

export default ({
  jobId,
  signatureType,
}: {
  jobId: string | number;
  signatureType: SignatureType;
}) => {
  return signatureType === SignatureType.ADVANCED_E_SIGNATURE
    ? `/oppdrag/${jobId}/avansert/velgid/`
    : `/oppdrag/${jobId}/autentisert/dokument/`;
};
