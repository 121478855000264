import { FunctionComponent, useMemo } from 'react';
import {
  JobDocument,
  JobDocumentsList,
} from '../jobs/jobDocuments/JobDocumentList';
import { useI18n, withParams } from '../language/i18n';

const JobDocuments: FunctionComponent<{
  documents: JobDocument[];
}> = ({ documents }) => {
  const [translation] = useI18n();

  const numberOfDocuments = useMemo(() => documents.length, [documents]);

  return (
    <>
      <span
        className="hw-block--pt-small-2 display-block"
        data-testid="job-documents-description"
        dangerouslySetInnerHTML={{
          __html: withParams(
            translation.common.signaturejob[
              numberOfDocuments === 1
                ? 'documents_for_signing_single'
                : 'documents_for_signing_multiple'
            ],
            { numberOfDocuments }
          ),
        }}
      />
      <JobDocumentsList documents={documents} />
    </>
  );
};

export default JobDocuments;
