import { FunctionComponent, MouseEventHandler } from 'react';

import FocusableElement from '../focus/FocusableElement';
import InformationContainer from '../informationContainer/InformationContainer';
import { SecondaryOutlinedButton, widths } from '@hedwig/buttons';
import { useI18n, withParams } from '../language/i18n';
import { del, useSigneringMutationApi } from '../api/signeringApi';
import ButtonWithSpinner from '@hedwig/buttons/ButtonWithSpinner';
import { ApiRequestStatus } from '../api/useApi';
import ButtonErrorMessage from '@hedwig/buttons/ButtonErrorMessage';
import { unsignedjobListHeading } from '../../portal/jobs/SignatureJobsWrapper';
import { OpenPortalJobSender } from '../../openPortal/jobs/openPortalSignatureJob';
import { useTracking } from '../analytics/Analytics';
import { AppName } from '../analytics/appNames';

const RejectJobAction: FunctionComponent<RejectJobActionProps> = ({
  sender,
  setFocus,
  onCancel,
  onSuccess,
  links,
  appName,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const { request, mutate } = useSigneringMutationApi(del, {
    onSuccess,
  });

  return (
    <InformationContainer
      extraClassName="reject--warning hw-block--mt hw-block--pt hw-block--pb"
      dataTestid="reject-signing-information"
    >
      <FocusableElement id="reject-job-warning">
        <h3 className="hw-h3 hw-text-title">
          {translation.portal.list.reject.header}
        </h3>
        <p
          id="reject-button-info"
          className="unsignedjob-message hw-block--mt-medium-2"
        >
          {withParams(translation.portal.list.reject.text, {
            sender: sender.name,
          })}
        </p>
        <div className="unsigned-job__reject-buttons hw-block--mt-medium-2">
          <ButtonWithSpinner
            onClick={() => {
              mutate(links.self);
              setFocus(unsignedjobListHeading);
              registerAnalyticsEvent({
                category: appName,
                action: 'Avviste oppdrag',
              });
            }}
            id="unsigned-job-reject-button-confirm"
            extraClassName={'unsigned-job__reject-buttons--confirm'}
            isLoading={request.status === ApiRequestStatus.PENDING}
          >
            {translation.portal.list.reject.button}
          </ButtonWithSpinner>
          <SecondaryOutlinedButton
            dataTestid="cancel-rejection-button"
            extraClassName="unsigned-job__reject-buttons--cancel"
            width={widths.responsive}
            onClick={onCancel}
          >
            {translation.portal.list.cancel_reject.button}
          </SecondaryOutlinedButton>
        </div>
        {request.status === ApiRequestStatus.FAILURE && (
          <ButtonErrorMessage
            ariaDescribedBy="unsigned-job-reject-button-confirm"
            errorMessage={translation.portal.list.reject.error}
          />
        )}
      </FocusableElement>
    </InformationContainer>
  );
};

interface RejectJobActionProps {
  sender: Pick<OpenPortalJobSender, 'name'>;
  links: {
    self: string;
  };
  onCancel: MouseEventHandler<HTMLButtonElement>;
  setFocus: (focus: string) => void;
  onSuccess: () => Promise<void>;
  appName: AppName;
}

export default RejectJobAction;
