import { FunctionComponent, PropsWithChildren } from 'react';

const SigningContainer: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <div className="content-wrapper content-wrapper-first content-fill-screen">
    <div className="box box-signing-process">{children}</div>
  </div>
);

export default SigningContainer;
