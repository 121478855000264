import {
  DecoderFunction,
  date,
  decodeType,
  record,
  string,
  union,
} from 'typescript-json-decoder';
import { maybeDecoder } from '../api/decoders';

export enum PadesStatus {
  RESOLVING = 'RESOLVING',
  AVAILABLE_UNTIL = 'AVAILABLE_UNTIL',
  DELETED = 'DELETED',
  DELETED_BY_SENDER = 'DELETED_BY_SENDER',
}
const padesStatusDecoder: DecoderFunction<PadesStatus> = (value) => {
  return union(...Object.values(PadesStatus))(value);
};

export type Pades = decodeType<typeof padesDecoder>;
export const padesDecoder = record({
  status: padesStatusDecoder,
  at: date,
  link: maybeDecoder(string),
});
