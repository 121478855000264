import { FunctionComponent } from 'react';

import { widths } from '@hedwig/buttons';
import {
  hasValidSecurityLevel,
  SecurityLevel,
} from '@common/idporten/securityLevel';
import { useI18n } from '@common/language/i18n';
import ForwardSignedDocumentToDigipostButton from './ForwardSignedDocumentToDigipostButton';
import { RecentlySignedJob } from '../recentlySignedJobs';
import { OrganizationSector } from '@common/organizations/organizationSector';
import { PadesStatus } from '@common/jobs/pades';
import { PUBLIC_LOGIN_URL_LEVEL4 } from '../../publicSectorLoginUrls';

function getCorrectLoginLink(job: { sector: OrganizationSector }): string {
  return job.sector === OrganizationSector.PUBLIC
    ? PUBLIC_LOGIN_URL_LEVEL4
    : '/logginn/privat';
}

const ForwardSignedDocumentToDigipost: FunctionComponent<
  ForwardSignedDocumentToDigipostProps
> = ({ job, authLevel }) => {
  const [translation] = useI18n();
  const forwardLink =
    job.pades?.status === PadesStatus.AVAILABLE_UNTIL
      ? hasValidSecurityLevel(job.securityLevel, authLevel)
        ? job.forwardSignedDocument?.href
        : getCorrectLoginLink(job)
      : undefined;

  return (
    <div className="table-cell-value__download hw-block--mb-medium-1">
      <ForwardSignedDocumentToDigipostButton
        extraClasses="save-document__button-and-text"
        extraButtonTextClasses="save-document__button-and-text"
        forwardLink={forwardLink}
        text={
          translation.portal.signing.signed.can_be_manually_forwarded.button
        }
        width={widths.responsive}
        view="Undertegnerportal"
      />
    </div>
  );
};

interface ForwardSignedDocumentToDigipostProps {
  authLevel: SecurityLevel;
  job: RecentlySignedJob;
}

export default ForwardSignedDocumentToDigipost;
