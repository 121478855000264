import { FunctionComponent } from 'react';

import OrganizationLogo from './organizationLogo/OrganizationLogo';

interface JobTitleAndLogoProps {
  title?: string;
  logoURL?: string;
}

export const JobTitleAndLogo: FunctionComponent<JobTitleAndLogoProps> = ({
  title,
  logoURL,
}) => {
  return (
    <div className="unsigned-job__header-section">
      <OrganizationLogo
        extraClassName="unsigned-job__sender-logo"
        logoUrl={logoURL}
      />
      <h2
        className="unsigned-job__header-title hw-h3 hw-text-title hw-color-primary-dark"
        data-testid="unsigned-job-title"
      >
        {title}
      </h2>
    </div>
  );
};
