import { FunctionComponent, useEffect, useMemo } from 'react';

import CompletedJobsTable from './completedJobs/CompletedJobsTable';
import Spinner from '@common/basics/Spinner';
import ErrorMessage from '@common/basics/ErrorMessage';
import {
  getUpsaleState,
  VIEWS_COMPLETED_JOBS_ACTION,
} from './completedJobs/analyticsTools';
import { useTracking } from '@common/analytics/Analytics';
import { getSecurityLevel } from '@common/session/session';
import { useI18n } from '@common/language/i18n';
import { App } from '../../apps';
import { useSession } from '@common/session/AppWithSession';
import { useSigneringApi } from '@common/api/signeringApi';
import { fetchRecentlySignedJobs } from './completedJobs/recentlySignedJobs';
import { ApiRequestStatus, isLoading } from '@common/api/useApi';

import './completedJobs/signed-jobs.less';

const CompletedJobs: FunctionComponent = () => {
  const { registerAnalyticsEvent } = useTracking();
  const [translation] = useI18n();
  const { session } = useSession();
  const authenticatedSecurityLevel = useMemo(
    () => getSecurityLevel(session),
    [session]
  );
  const { request } = useSigneringApi(fetchRecentlySignedJobs);

  useEffect(() => {
    if (
      request.status === ApiRequestStatus.SUCCESS &&
      request.result.length !== 0
    ) {
      registerAnalyticsEvent({
        category: App.SIGNATURE_PORTAL,
        action: VIEWS_COMPLETED_JOBS_ACTION,
        label: getUpsaleState(request.result),
      });
    }
  }, [request, registerAnalyticsEvent]);

  return (
    <section className="signed-jobs">
      <h2 className="hw-h2">{translation.portal.log.header}</h2>
      <Spinner condition={isLoading(request.status)} />
      {request.status === ApiRequestStatus.FAILURE && (
        <ErrorMessage message={translation.portal.log.loading_failed} />
      )}
      {request.status === ApiRequestStatus.SUCCESS && (
        <>
          {request.result.length === 0 ? (
            <h3 className="hw-block--mt-medium-3" aria-live="polite">
              {translation.portal.log.empty.heading}
            </h3>
          ) : (
            <CompletedJobsTable
              authenticatedSecurityLevel={authenticatedSecurityLevel}
              jobs={request.result}
            />
          )}
        </>
      )}
    </section>
  );
};

export default CompletedJobs;
