import { DecoderFunction, union } from 'typescript-json-decoder';

export enum ForwardSignedDocumentStatus {
  WILL_BE_FORWARDED_TO_PUBLIC_MAILBOX = 'WILL_BE_FORWARDED_TO_PUBLIC_MAILBOX',
  CAN_BE_FORWARDED_TO_PUBLIC_MAILBOX_BUT_IS_NOT_REGISTERED = 'CAN_BE_FORWARDED_TO_PUBLIC_MAILBOX_BUT_IS_NOT_REGISTERED',
  HAS_BEEN_FORWARDED_TO_PUBLIC_MAILBOX = 'HAS_BEEN_FORWARDED_TO_PUBLIC_MAILBOX',
  COULD_NOT_FORWARD_TO_PUBLIC_MAILBOX = 'COULD_NOT_FORWARD_TO_PUBLIC_MAILBOX',
  WILL_BE_FORWARDED_TO_DIGIPOST = 'WILL_BE_FORWARDED_TO_DIGIPOST',
  CAN_BE_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED = 'CAN_BE_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED',
  HAS_BEEN_FORWARDED_TO_DIGIPOST = 'HAS_BEEN_FORWARDED_TO_DIGIPOST',
  COULD_NOT_FORWARD_TO_DIGIPOST = 'COULD_NOT_FORWARD_TO_DIGIPOST',
  CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST = 'CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST',
  CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED = 'CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED',
  WILL_NOT_BE_FORWARDED = 'WILL_NOT_BE_FORWARDED',
  UNKNOWN = 'UNKNOWN',
}
export const forwardSignedDocumentStatusDecoder: DecoderFunction<
  ForwardSignedDocumentStatus
> = (value) => {
  return union(...Object.values(ForwardSignedDocumentStatus))(value);
};

export const convertForwardSignedDocumentStatusToAnalyticsLabel = (
  status: ForwardSignedDocumentStatus
) => {
  switch (status) {
    case ForwardSignedDocumentStatus.WILL_BE_FORWARDED_TO_DIGIPOST:
    case ForwardSignedDocumentStatus.HAS_BEEN_FORWARDED_TO_DIGIPOST:
      return 'Videresendt til Digipost';
    case ForwardSignedDocumentStatus.CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST:
      return 'Manuell videresending til Digipost tilgjengelig';
    case ForwardSignedDocumentStatus.WILL_BE_FORWARDED_TO_PUBLIC_MAILBOX:
    case ForwardSignedDocumentStatus.HAS_BEEN_FORWARDED_TO_PUBLIC_MAILBOX:
      return 'Videresendt til offentlig postkasse';
    case ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED:
      return 'Digipost oppsalg';
    case ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_PUBLIC_MAILBOX_BUT_IS_NOT_REGISTERED:
      return 'Offentlig oppsalg';
    case ForwardSignedDocumentStatus.UNKNOWN:
      return 'Oppsalg med ukjent registreringsstatus';
    case ForwardSignedDocumentStatus.WILL_NOT_BE_FORWARDED:
    case ForwardSignedDocumentStatus.COULD_NOT_FORWARD_TO_DIGIPOST:
    case ForwardSignedDocumentStatus.COULD_NOT_FORWARD_TO_PUBLIC_MAILBOX:
    case ForwardSignedDocumentStatus.CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED:
      return 'Skal ikke videresendes';
  }
};
