import {
  array,
  date,
  decodeType,
  number,
  record,
  string,
} from 'typescript-json-decoder';
import { API_PORTAL_ROOT } from '@common/api/apiUrls';
import { maybeDecoder } from '@common/api/decoders';
import { createSigneringApiDataFetcher } from '@common/api/signeringApi';
import { organizationSectorDecoder } from '@common/organizations/organizationSector';
import { securityLevelDecoder } from '@common/idporten/securityLevel';
import {
  ForwardSignedDocumentStatus,
  forwardSignedDocumentStatusDecoder,
} from '../../forwarding/forwardSignedDocumentStatus';
import { padesDecoder } from '@common/jobs/pades';

export interface ForwardSignedDocument {
  status: ForwardSignedDocumentStatus;
  href: string;
}
const forwardSignedDocumentDecoder = record({
  status: forwardSignedDocumentStatusDecoder,
  href: string,
});

export type RecentlySignedJob = decodeType<typeof recentlySignedJobDecoder>;
const recentlySignedJobDecoder = record({
  id: number,
  signedAt: date,
  sender: string,
  numberOfSigners: number,
  numberOfSignedSigners: number,
  subject: maybeDecoder(string),
  forwardSignedDocument: forwardSignedDocumentDecoder,
  sector: organizationSectorDecoder,
  jobCompletedAt: maybeDecoder(date),
  securityLevel: securityLevelDecoder,
  pades: maybeDecoder(padesDecoder),
});

export const fetchRecentlySignedJob = createSigneringApiDataFetcher({
  name: 'RecentlySignedJob',
  url: (jobId: number | string) =>
    `${API_PORTAL_ROOT}/signature-jobs/recently_signed/${jobId}`,
  decoder: recentlySignedJobDecoder,
});

export const fetchRecentlySignedJobs = createSigneringApiDataFetcher({
  name: 'RecentlySignedJobs',
  url: `${API_PORTAL_ROOT}/signature-jobs/recently_signed`,
  decoder: array(recentlySignedJobDecoder),
});
