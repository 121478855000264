import {
  array,
  boolean,
  date,
  decodeType,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import { API_ORG_ROOT } from '@common/api/apiUrls';
import { maybeDecoder } from '@common/api/decoders';
import { createSigneringApiDataFetcher } from '@common/api/signeringApi';

export type ChoosableOrganizationForTrustee = decodeType<
  typeof choosableOrganizationForTrusteeDecoder
>;
const choosableOrganizationForTrusteeDecoder = record({
  id: number,
  orgnr: string,
  name: string,
  hasAccess: boolean,
  trusteeExpirationDate: maybeDecoder(date),
  logoUrl: string,
});

export const fetchChoosableOrganizationsForTrustee =
  createSigneringApiDataFetcher({
    name: 'ChoosableOrganizationsForTrustee',
    url: API_ORG_ROOT,
    decoder: array(choosableOrganizationForTrusteeDecoder),
  });
